import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { TransUnionScreeningStatus } from '~/types/RentalApplication';
import { MagicUseCase } from '~/use-cases/magicUseCase';
import { GetRentalApplicationScreeningQuestionsUseCase } from '../screening/getRentalApplicationScreeningQuestionsUseCase';

export class GetRentalApplicationStatusUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    const [isPaid, transUnionScreeningStatus] = await Promise.all([
      rentalApplicationGateway.fetchRentalApplicationPaymentStatus(credentials),
      rentalApplicationGateway.getTransUnionScreeningStatus(credentials),
    ]);
    this.getState().user.rentalApplication.isPaid = isPaid;
    this.getState().user.rentalApplication.transUnionScreening = transUnionScreeningStatus;
    if (this.getState().user.rentalApplication.transUnionScreening?.status === TransUnionScreeningStatus.UnVerified) {
      await new GetRentalApplicationScreeningQuestionsUseCase().execute(credentials);
    } else if (this.getState().user.rentalApplication.transUnionScreening?.status === TransUnionScreeningStatus.Verified) {
      this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/application-submitted`);
    } else if (
      this.getState().user.rentalApplication.transUnionScreening?.status === TransUnionScreeningStatus.ManualVerificationRequired
    ) {
      this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/manual-verification`);
    }
  }
}
